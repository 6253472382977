
import React, { useEffect } from 'react'
import TagCloud from 'TagCloud';
import './../styles/wordcloud.scss';


//const TagCloud = require('TagCloud');
const container = '.content';
const texts = [
    'Power', 'BI', ' Lean Six Sigma', 'Agilité', 'Scrum', 'PMP', 'PMI', 'Prince2', 'Lean Management', 'BPMN', 'Management Stratégique', 'Risk Management', 'Intelligence Artificielle', 'Développement Web Full Stack',
    'Cybersécurité', 'Excellence Opérationnelle', 'Data Analyst', 'Data Engineer', 'Cloud', 'Growth Hacking',
    'Marketing Digital', 'Community Management', 'Commerce International', 'Vente',
];
const options = {
    radius: 300,
    // animation speed
    // slow, normal, fast
    maxSpeed: 'normal',
    initSpeed: 'normal',
    // 0 = top
    //90 = left
    // 135 = right-bottom
    direction: 135,
    // interact with cursor move on mouse out
    keep: true
};

const WordCloud = () => {
    // to render wordcloud each time the page is reloaded
    useEffect(() => {
        TagCloud(container, texts, options);
        console.log("hey");
    }, []);

    return (
        <div className='main wcloud'>
            <span className="content"></span>
        </div>
    )
}

export default WordCloud;


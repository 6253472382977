import React, { useState } from 'react';
import { Grid } from "@mui/material";
import emailjs from 'emailjs-com';

function Inscription(props) {
  const { formation } = props;
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    email: '',
    telephone: '',
    formation: formation.name, // Initialiser avec la valeur de formation
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_xj9lpdi';
    const templateId = 'template_4hu2o84';
    const userId = 'c2xJkv3dt4ltByVaj';

    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        setResponseMessage('Votre demande d\'inscription a été envoyée avec succès !');
        setFormData({
          prenom: '',
          nom: '',
          email: '',
          telephone: '',
          formation: formation.name, // Réinitialisez la valeur de formation
        });
      })
      .catch((error) => {
        setResponseMessage('Erreur lors de l\'envoi de la demande.');
        console.error('Erreur lors de l\'envoi de l\'email :', error);
      });
  };

  // Gérer le changement des champs du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <div className="inscription_section">
      <div className="row justify-content-center d-flex flex-column align-items-center">
        <div className="col-md-12">
          <div className="form">
            <div className="titleSection">
              <h3 className="text-center text1">Demande d'inscription</h3>
              <h4 className="text-center text2 pb-4">Soumet tes coordonnées</h4>
            </div>
            <form className="text-center" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="d-flex justify-content-center" style={{ boxShadow: "none" }}>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="prenom"
                    type="text"
                    className="form-control"
                    id="prenom"
                    placeholder="Prénom"
                    onChange={handleChange}
                    value={formData.prenom}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="nom"
                    type="text"
                    className="form-control"
                    id="nom"
                    placeholder="Nom"
                    onChange={handleChange}
                    value={formData.nom}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="email"
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="E-mail"
                    required
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="telephone"
                    type="tel"
                    className="form-control"
                    id="telephone"
                    placeholder="Téléphone"
                    onChange={handleChange}
                    value={formData.telephone}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="formation"
                    type="text"
                    className="form-control"
                    id="formation"
                    placeholder="Formation"
                    disabled
                    value={formData.formation} // Utilisez la valeur du state local
                  />
                </Grid>
              </Grid>
              <p className="text-center mt-4" style={{ color: "#F78323" }}>{responseMessage}</p>
              <div className="col-12"><button className="btn btn-primary mt-4" type="submit">Soumettre</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inscription;

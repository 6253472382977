import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { useRef } from "react";
import { Link } from "react-router-dom";
import WordCloud from "./WordCloud";
import Offre from "./Offre";


function DomaineSection(props) {
    const {domaines} = props;
    const sectionRef = useRef(null);
    return (
        <>
<div className="domaine_section" ref={sectionRef} id="jemeforme">
  <div className="domaine_section_item py-4">
    <div className="titleSection text-center py-5">
      <h3 className="texte1">JE ME FORME</h3>
      <h2 className="texte2">
        My <span className="texte2c">SKILLS</span>, My{" "}
        <span className="texte2c">VALUE</span>
      </h2>
      {/* <h6 className="texte3">Développe tes compétences et valorise ton expertise</h6>
      <h6 className="texte3">en te formant aux métiers de demain</h6> */}
    </div>
    <WordCloud/> 
    <Offre/>
    <div className="row d-flex justify-content-center align-items-center mt-2"> {/* Utilisation de 'justify-content-center' et 'align-items-center' pour centrer les éléments horizontalement et verticalement */}
      {domaines.map((domaine) => (
        <div key={domaine.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3 my-4 d-flex justify-content-center px-0">
          <a href={`/formation/${domaine.name}-${domaine.id}`}>
            <Card className="cardformation mx-1">
              {/* <div className="avenir text-center"><h3 className="pt-5">A venir</h3></div> */}
              <CardActionArea>
                <div className="cardinfo">
                  <CardMedia
                    component="img"
                    style={{ height: "100%", padding: "10px 10px 0px 10px",borderRadius:"25px"}}
                    image={domaine.photo}
                    alt=""
                    className=""
                  />
                </div>

                <CardContent className="d-flex justify-content-between align-items-center">
                  <div className="nomformation">{domaine.name}</div>
                  <div className="cerclefleche"><FontAwesomeIcon icon={faArrowRight} className="fleche"/></div>
                </CardContent>
              </CardActionArea>
            </Card>
          </a>
        </div>
      ))}
    </div>
    <div className="row my-4 d-flex justify-content-around">
        <div className="col-md-4 text-start">
        <div className="textbloc1">Choisis ta formation et valide la formule</div>
        <div className="textbloc2">qui te convient <img src="/img/fleche.svg" alt="" className="lafleche ms-2"/></div>
        </div>
        <div className="col-md-4  text-end">
            <a href="https://cloud-elearning.online/" ><button className='btnjemeforme shadow'>Je me forme</button></a>
        </div>
    </div>
  </div>
</div>


        </>
    );
}

export default DomaineSection;
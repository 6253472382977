import React, { useEffect } from 'react';
import Footer from '../components/fotter';
import Header from '../components/header';
import Offre from '../components/Offre';
import { Card } from 'react-bootstrap';
import { CardActionArea, CardContent, CardMedia } from '@mui/material';
import InscriptionForm from '../components/InscriptionForm';
import { useLocation } from 'react-router-dom';
import WhatsAppWidget from '../components/WhatsAppWidget';
const themes = require('../configs/fomations.json')

const Inscription = () => {
    const location = useLocation();
    const { state } = location;
    // Maintenant, state contient les données d'état que vous avez transmises lors de la navigation
    const { duree, prix, description} = state || {};

    useEffect(() => {
        // Redirigez l'utilisateur vers le haut de la page lorsque le composant est monté
        window.scrollTo(0, 0);
      }, []); 
    
    return (
        <div className="abonnement_section">
            <Header />
            <div className="topabonnement">
                <div className="title">
               
               
                <div className="container">
                <div className="row justify-content-start">
                    <div className="col-md-6">
                    <h4 className="text-uppercase pb-4 formationname text-start">Je m'inscris pour une durée de : <br /> <span className="duree">{duree}</span> </h4>
                    </div>
                </div>
                </div>
                </div>
                
            </div>
            <div className="container my-4">
                <div className="row align-items-center">
                    <div className="col-md-7">
                    <div class="card mb-5 d-none d-md-block" style={{border:"1px dashed #F78323",marginTop:"-300px"}}>
                        <h2 class=" px-4 pt-4 mb-0 fw-bold objectifs">{prix} FCFA</h2>
                        <div class="card-body">
                            <p class="card-text">
                               {description}
                            </p>
                        </div>
                    </div>
                    <div class="card mb-5 d-block d-md-none">
                        <h2 class=" px-4 pt-4 mb-0 fw-bold objectifs">{prix} FCFA</h2>
                        <div class="card-body">
                            <p class="card-text">
                            {description}
                            </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-5 d-none d-md-block">
                        <Card className="cardinscription mx-1 shadow" style={{border:"none",marginTop:"-200px",background:"#F4F5FB"}}>
                            <CardActionArea>
                                <div style={{ position: 'relative' }}>
                                <CardMedia
                                    component="img"
                                    style={{ height: "100%", padding: "15px 15px 0px 15px" }}
                                    image="img/imagepaiement.svg"
                                    alt=""
                                    className=""
                                />
                                </div>

                                <CardContent className="d-flex justify-content-between align-items-center">
                                <InscriptionForm prix={prix} duree={duree}  />
                                
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    <div className="col-md-5 d-block d-md-none">
                        <Card className="cardinscription mx-1 shadow" style={{border:"none",background:"#F4F5FB"}}>
                            <CardActionArea>
                                <div style={{ position: 'relative' }}>
                                <CardMedia
                                    component="img"
                                    style={{ height: "100%", padding: "15px 15px 0px 15px" }}
                                    image="img/imagepaiement.svg"
                                    alt=""
                                    className=""
                                />
                                </div>

                                <CardContent className="d-flex justify-content-between align-items-center">
                                <InscriptionForm prix={prix} duree={duree}  />
                                
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>

                </div>
            </div>
            <Footer/>
            <WhatsAppWidget/>
        </div>
    );
};

export default Inscription;
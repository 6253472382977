import { useEffect } from "react";

function TextAnnimate() {

    useEffect(() => {
        const script = document.createElement('script');
      
         script.src = "/js/annimTExt.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (  
        <div className="">
             
            
        </div>
     );
}

export default TextAnnimate;
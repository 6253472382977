
import React, { useState } from 'react';
import { Grid } from "@mui/material";
import axios from 'axios';

function InscriptionForm({ duree, prix }) {
  const [formData, setFormData] = useState({
    nomcomplet: '',
    email: '',
    telephone: '',
    prix: prix,
    duree:duree
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    const ff = new FormData()
    ff.append('nomcomplet', formData.nomcomplet)
    ff.append('email', formData.email)
    ff.append('telephone', formData.telephone)
    ff.append('prix', formData.prix)
    ff.append('duree', formData.duree)
  
    axios.post('http://backend.certyup.jobs/index.php', ff)
    .then((res) => {
        console.log(res);
        window.location.href = res.data;
    })
    .catch((error) => {
        console.error('Error during axios request:', error);
        // Ajoutez une logique de gestion des erreurs ici si nécessaire
    });

};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="inscription_section">
      <div className="row justify-content-center d-flex flex-column align-items-center">
        <div className="col-md-12">
          <div className="form">
            <div className="titleSection">
              <h3 className="text-center text1">Abonnement</h3>
              <h4 className="text-center text2 pb-4">Soumettez vos informations personnelles</h4>
            </div>
            <form className="text-center" onSubmit={handleSubmit}>
            <Grid container spacing={2} className="d-flex justify-content-center" style={{ boxShadow: "none" }}>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="nomcomplet"
                    type="text"
                    className="form-control"
                    id="nomcomplet"
                    placeholder="Nom Complet"
                    onChange={handleChange}
                    value={formData.nomcomplet}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="email"
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="E-mail"
                    required
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="off"
                    name="telephone"
                    type="tel"
                    pattern="[0-9]*"
                    className="form-control"
                    id="telephone"
                    placeholder="Téléphone"
                    onChange={handleChange}
                    value={formData.telephone}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <input
                    autoComplete="fname"
                    name="duree"
                    type="text"
                    className="form-control"
                    id="duree"
                   
                    disabled
                    value={duree}
                  />
                </Grid>
                <div className="col-12">
                  <button className="btn btn-primary mt-4" type="submit">Passer au paiement</button>
                </div>
              </Grid>
              <p className="text-center mt-4" style={{ color: "#F78323" }}>{responseMessage}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InscriptionForm;

 
              
import React from "react";
import Image from "react-bootstrap/Image";
import "./../styles/banniere.scss";
import { Navigate, useNavigate } from "react-router-dom";

export default function BannierPub() {
 
  return (
    <div className="banniere">
      <h2 className="banniere-h2">Formation à la une</h2>
      <h1 className="banniere-h1">Influ-vendeur</h1>
      <p className="banniere-p1">
        Ton job est assuré à la fin de la formation
        {/* <span className="nn"></span> */}
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="bannierep-p2"> Processus d’inscription</p>
        <a href="/#contact">
          <button className="btn btn-primary banniere-btn">
            Contacte-nous
          </button>
        </a>
      </div>
      </div>
  );
}

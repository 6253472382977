import logo from './logo.svg';
import './styles/app.scss';
import Accueil from './pages/Accueil';
import Formation from './pages/Formation';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Abonnement from './pages/Abonnement';
import Inscription from './pages/inscription';

const fichierformationsjson = require('./configs/fomations.json')

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Accueil />} />
      <Route path="/inscription" element={<Inscription/>} />
      <Route path="/formation/:name-:id" element={<Formation listeformations={fichierformationsjson.formations}/>} />
      <Route path="/abonnement" element={<Abonnement/>} />

    </Routes>
  </BrowserRouter>
  );
}

export default App;

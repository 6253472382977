import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation, useNavigate } from 'react-router-dom';

function Header(props) { 
  const navigate = useNavigate();
  const location = useLocation ();

  const [bgColor, setBgColor] = useState('#fff');
  const [bgBorder, setBgBorder] = useState('3px solid #f88a11');
  const [menuOpen, setMenuOpen] = useState(false)



  const navStyle = {
    background : bgColor,
    // borderBottom : bgBorder
  }
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  const handleClose = () => setMenuOpen(false)

 

  // const scrollToSectionContact = () => {
  //   const section = document.querySelector("#contact");
  //   if (section) {
  //     handleClose();
  //     section.scrollIntoView({ behavior: "smooth" });
      
  //   }
  // };
  const scrollToSection = ( ancre ) => {
    const section = document.querySelector(ancre);
    if (section) {
      handleClose();
      section.scrollIntoView({ behavior: "smooth" }); 
    }
  };

  const handleNavClick = (hash) => {
    if (location.pathname !== '/'){
      navigate(`/${hash}`);
      scrollToSection(hash);
    }else {
      scrollToSection(hash);
    }
  }
  // const scrollToSectionCertifier = () => {
  //   const section = document.querySelector("#jemecertifie");
  //   if (section) {
  //     handleClose();
  //     section.scrollIntoView({ behavior: "smooth" });
      
  //   }
  // };
  // const scrollToSectionForfait = () => {
  //   const section = document.querySelector("#nosforfaits");
  //   if (section) {
  //     handleClose();
  //     section.scrollIntoView({ behavior: "smooth" });
      
  //   }
  // };
  // const scrollToSectionBosser = () => {
  //   const section = document.querySelector("#jebosse");
  //   if (section) {
  //     handleClose();
  //     section.scrollIntoView({ behavior: "smooth" });
      
  //   }
  // };
  return (
    <div className='topHeader'>
      {['md'].map((expand) => (
        <>
       <Navbar key={expand} style={navStyle} expand={expand} fixed='top' className='shadow'>
  <Container>
    <Navbar.Brand href="/">
      <img src="/img/logocertyup.svg" alt="logo certyUp" style={{ width: "150px" }}/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={toggleMenu} />
    <Navbar.Offcanvas
      id={`offcanvasNavbar-expand-${expand}`}
      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
      placement="end"
      show={menuOpen}
    >
      <Offcanvas.Header closeButton onClick={handleClose}>
        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
          <img src="/img/logocertyup.svg" alt="logo certyUp" style={{ width: '100px' }} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="justify-content-center flex-grow-1 pe-4 ">
          {/* <Nav.Link to="/jemeforme" className='mylink me-4 text-none' onClick={() => {scrollToSection("#jemeforme");}}>Je me forme</Nav.Link> */}
          {/* <Nav.Link to="/jemecertifie" className='mylink me-4 text-none' onClick={() => {scrollToSection("#jemecertifie");}}>Je me certifie</Nav.Link> */}
          {/* <Nav.Link to="/nosforfaits" className='mylink me-4 text-none' onClick={() => {scrollToSection("#nosforfaits");}}>Nos forfaits</Nav.Link> */}
          {/* <Nav.Link to="/jebosse" className='mylink me-4 text-none' onClick={() => {scrollToSection("#jebosse");}}>Je bosse</Nav.Link> */}
          {/* <Nav.Link to="/contact" className='mylink me-4 text-none'  onClick={() => {scrollToSection("#contact");}}>Contacte-nous</Nav.Link> */}

          <Nav.Link to="/#jemeforme" className='mylink me-4 text-none' onClick={(e) => {e.preventDefault(); handleNavClick("#jemeforme");}}>Je me forme</Nav.Link>
          <Nav.Link to="/#jemecertifie" className='mylink me-4 text-none' onClick={(e) => {e.preventDefault(); handleNavClick("#jemecertifie");}}>Je me certifie</Nav.Link>
          <Nav.Link to="/#nosforfaits" className='mylink me-4 text-none' onClick={(e) => {e.preventDefault(); handleNavClick("#nosforfaits");}}>Nos forfaits</Nav.Link>
          <Nav.Link to="/#jebosse" className='mylink me-4 text-none' onClick={(e) => {e.preventDefault(); handleNavClick("#jebosse");}}>Je bosse</Nav.Link>
          <Nav.Link to="/#contact" className='mylink me-4 text-none' onClick={(e) => {e.preventDefault(); handleNavClick("#contact");}}>Contacte-nous</Nav.Link>
        </Nav>
        <Nav className="d-block align-items-center">
          <Nav.Link href="https://certyup.cloud-elearning.online" target="_blank" className='text-primary text-none'><button className='btncertyup shadow'>Accède à CertyUp</button></Nav.Link>
          <Nav.Link href="#" className='text-primary text-none'><button className='btnfindyup shadow'><div className='findy d-flex align-items-center justify-content-center'>en maintenance</div> Accède à FindyUp</button></Nav.Link>
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>


        </>
      ))}
     
    </div>
  );
}

export default Header;